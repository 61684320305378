<template>
    <div style="margin: 5px;">
        <b-modal
            id="importModal"
            ref="importModal"
            size="lg"
            title="Extra Large Modal"
        >
            <template #modal-header>
                <h5>Import {{ $helper.ucFirst(model) }}</h5>
            </template>
            <b-container
                fluid
                class="mb-2"
            >
                <b-form-group
                    label="Branch"
                    label-for="branch_id"
                >
                    <custom-autopopulate
                        id="branch_id"
                        module="branches"
                        label="name"
                        @changed="branchesChanged"
                    />
                </b-form-group>
                <small
                    id="fileHelp"
                    class="form-text text-danger"
                >{{ inputError }}</small>
                <b-form-file
                    ref="file-input"
                    v-model="file"
                    accept=".csv"
                    :state="checkFile"
                    placeholder="Choose a file or drop it here..."
                />
            </b-container>
            <b-container
                fluid
            >
                or, You can download a sample file from  <a
                    @click.prevent="downloadSample"
                ><b>here</b></a>
            </b-container>
            <template #modal-footer>
                <div class="w-100">
                <b-button
                    variant="primary"
                    size="sm"
                    class="float-right"
                    :disabled="!checkFile"
                    @click="save"
                >
                    Import
                </b-button>
                </div>
            </template>
        </b-modal>
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-success"
            class="btn-icon rounded-circle"
            title="Import"
            @click="openModal"
        >
            <feather-icon
                icon="UploadIcon"
                svg-classes="h-10 w-10"
            />
        </b-button>
    </div>
</template>
<script>
import {
    BButton,
    BModal,
    BContainer,
    BFormFile,
    BFormGroup,
    BRow,
    BCol,
    BCardText,
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Ripple from 'vue-ripple-directive';
import CustomAutopopulate from './components/CustomAutopopulate';
export default {
    name: 'Import',
    components: {
        BButton,
        BModal,
        BContainer,
        BFormFile,
        // BRow,
        // BCol,
        // BCardText,
        BFormGroup,
        CustomAutopopulate,
    },
    directives: {
        Ripple,
    },
    props: {
        model: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            file: null,
            acceptableFileTypes: ['text/csv'],
            id: '',
            name: '',
            memberCondition: {
                where_fields: [],
                where_values: [],
            },
        }
    },
    computed: {
        checkFile() {
            if (this.file && this.acceptableFileTypes.indexOf(this.file.type) > -1) return true;
            return false;
        },
        inputError() {
            if (this.file && this.checkFile) {
                return ''
            } if (this.file && !this.checkFile) {
                return 'Please Select a Valid File'
            }
                return 'Please Choose a File'
        },
    },
    // async created() {
    //     this.fetchData();
    // },
    methods: {
        openModal() {
            this.$refs.importModal.show();
        },
        downloadSample() {
            const rows = [
                ["First Name", "Last Name", "Email", "Phone Number", "Player ID", "Membership Level", "DOB", "Gender", "Street Address", "City", "Postal Code", "Country"],
                ["John", "Doe", "john.doe@example.com", "(+1) 2333332", "REQUIRED", "Rewards/Rewards Plus/Legend/Prestige/Elite", "1994-12-12", "Male/Female/Others", "Street Address", "City", "Postal Code", "Country"],
            ];
            this.$refs.importModal.hide();
            this.file = null;
            this.downloadCSV(rows, 'member_import_demo.csv');
        },
        downloadCSV(rows, filename) {
            const csvContent = `data:text/csv;charset=utf-8,${rows.map(e => e.join(",")).join("\n")}`;
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", filename);
            document.body.appendChild(link); // Required for FF
            link.click();
        },
        save() {
            const formData = new FormData();
            formData.append('file', this.file);
            formData.append('branch_id', this.id);
            this.$axios.post(`${this.model}/importCsv`, formData).then(resp => {
                this.$helper.hideLoading();
                if (resp.data.status) {
                    this.$toast({
                        component: ToastificationContent,
                            props: {
                                title: resp.data.message,
                                icon: 'CheckCircleIcon',
                                variant: 'Primary',
                            },
                    });
                    if (resp.data.error) {
                        const rows = [
                            [...resp.data.header],
                        ];
                        // eslint-disable-next-line no-restricted-syntax
                        // eslint-disable-next-line no-restricted-syntax
                        for (const item of resp.data.error_rows) {
                            if (item) {
                                rows.push([
                                    ...Object.values(item),
                                ]);
                            }
                        }
                        this.downloadCSV(rows, "import_error.csv");
                    }
                    this.$refs.importModal.hide();
                    this.file = null;
                    this.$emit('imported');
                } else {
                    this.$toast({
                    component: ToastificationContent,
                        props: {
                            title: resp.data.message,
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                }
            }).catch(err => {
                this.$helper.hideLoading();
                this.$toast({
                    component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
            });
        },
        branchesChanged(val) {
            this.id = val;
        },
    },
}
</script>
