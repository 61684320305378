<template>
    <div>
        <b-tabs @input="fetchData">
            <b-tab
                :title="`Equipment Classes (${totalClasses})`"
                active
                :lazy="true"
            >
                <List
                    model="equipmentclasses"
                    :can-delete="true"
                    :can-edit="true"
                    :can-view="false"
                    default-sort="id"
                    default-sort-order="desc"
                    @needRefresh="fetchData"
                />
            </b-tab>
            <b-tab
                :title="`Wheel Layout (${totalWheellayout})`"
                :lazy="true"
            >
                <List
                    model="wheellayouts"
                    :can-delete="true"
                    :can-edit="true"
                    :can-view="false"
                    default-sort="id"
                    default-sort-order="desc"
                    @needRefresh="fetchData"
                />
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import {
    BTabs,
    BTab,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import List from '../common/list';
import CustomAutopopulate from '../common/components/CustomAutopopulate';
export default {
    components: {
        BTabs,
        BTab,
        List,
    },
    data() {
        return {
            totalClasses: 'loading...',
            totalWheellayout: 'loading...',
        };
    },
    async created() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            const response = await this.$axios.get('/wheellayouts/statistics');
            this.totalClasses = response.data.equipment_classes;
            this.totalWheellayout = response.data.wheel_layouts;
        },
        refreshList() {
            console.log('called');
        },
    },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.custom-control.custom-checkbox {
    top: 40%;
}
.checkbox-container {
    justify-content: space-around;
    padding: 2rem 0;
}
</style>
